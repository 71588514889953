<template>
    <a-layout>
        <a-layout-content>
            <a-tabs default-active-key="1">
                <template slot="tabBarExtraContent">
                    <a-input-search placeholder="请输入查询内容" v-model="keyword" style="width: 200px" @search="onSearch" />
                    <a-button type="primary" size="small" class="add-article" @click="popup">
                        <span class="iconfont icon-piliangtianjia"></span>新增原文
                    </a-button>
                    <a-button type="primary" size="small" class="del-article" @click="multiDelete">
                        <span class="iconfont icon-shanchu"></span>删除原文
                    </a-button>
                </template>
                <a-tab-pane key="1" tab="源数据文档"></a-tab-pane>
            </a-tabs>
            <div class="table-wrap">
                <a-table :pagination="pageSetting" :rowKey="(r, i) => i" :columns="allColumns" @change="changeTable" :data-source="articles" :row-selection="rowSelection">
                        <template slot="operation" slot-scope="text,record">
                            <a-button type="link" @click="edit(record)">编辑</a-button>
                            <a-button type="link" @click="deleteArticle(record.autoId)">删除</a-button>
                            <a-button type="link" @click="downloadFile(record.autoId)">下载</a-button>
                        </template>
                        <template slot="memoDesc" slot-scope="text">
                            <span v-html="text"></span>
                        </template>
                </a-table>
            </div>

            <a-modal 
                :title="waitingEdit?'编辑原文':'新增原文'"
                :visible="editVisible"
                centered
                :footer="null"
                :width="1082"
                wrapClassName="edit-cube-win"
                @cancel="cancelEdit()">

                <div class="inputs">
                    <a-row :gutter="[16,16]">
                        <a-col :span="24">
                            <a-row type="flex">
                                <a-col flex="100px" class="label">文件名称</a-col>
                                <a-col flex="auto">
                                    <a-input v-model="fileName" placeholder="请输入文件名称"/>
                                </a-col>
                            </a-row>
                        </a-col>
                    </a-row>                    
                    <a-row type="flex" :gutter="[16,32]">
                        <a-col flex="100px">原文维度</a-col>
                        <a-col flex="200px">
                            <!-- <a-select
                            style="width: 100%"
                            placeholder="选择数据库"
                            v-model="cubeName"
                            >
                                <a-select-option @click="handleChangeCube(item.cubeId)" :value="item.cubeNameZh" v-for="item in userInfo.metaCubeList" :key="item.cubeId">
                                {{item.cubeNameZh}}
                                </a-select-option>
                            </a-select> -->
                     <a-tree-select
                        v-model="cubeName"
                        style="width: 100%"
                        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                        placeholder="选择数据库"
                        @change="handleChangeCube"
                    >
                        <a-tree-select-node :selectable="false" :title="item.cubeNameZh" :value="item.cubeNameZh"  v-for="item in metaCubeList" :key="item.cubeId">
                            <a-tree-select-node :key="i.cubeId" :value="i.cubeNameZh" :title="i.cubeNameZh" v-for="i in item.cubeList" />
                        </a-tree-select-node>
                    </a-tree-select>
                        </a-col>
                        <a-col flex="250px">
                            <div>
                                <a-dropdown :trigger="['click']" :disabled="!cubeId" :class="{'disabledStyle':!cubeId}">
                                <div class="ant-dropdown-link" @click.prevent="selectIndicator">
                                    <span v-if="!selectedIndicatorList.length">选择指标</span>
                                    <span v-else style="color:#595959">已选择指标（{{selectedIndicatorList.length}}）</span>
                                    <a-icon type="down" />
                                </div>
                                <div slot="overlay" class="regionList">
                                        <!-- <div class="dim-search" @click.stop>
                                            <a-input-search size="small" />
                                        </div> -->
                                        <div class="dim-scroll" @click.stop>
                                            <vue-scroll :ops="ops" style="height:100%">
                                                <a-tree
                                                        v-model="checkedKeysIndicator"
                                                        checkable
                                                        checkStrictly
                                                        :load-data="e=>{return onLoadData(e,'INDICATOR',indicatorSheetId)}"                                                       
                                                        :tree-data="IndicatorList"
                                                        :replaceFields="{title:'dimName',key:'dimCode',children:'children'}"
                                                    >
                                                    <template #title="node">
                                                        <a-dropdown :trigger="['contextmenu']">
                                                            <span>{{ node.dimName }}</span>
                                                            <template #overlay>
                                                                <a-menu @click="({key:menuKey}) => onContextMenuClick(menuKey,node,'INDICATOR',indicatorSheetId)">
                                                                    <a-menu-item key="5">全选</a-menu-item>
                                                                    <a-menu-item key="6">取消全选</a-menu-item>
                                                                    <a-menu-item key="1">选择同级</a-menu-item>
                                                                    <a-menu-item key="2">取消同级</a-menu-item>
                                                                    <a-menu-item key="3" v-if="node.isParent">选择子项</a-menu-item>
                                                                    <a-menu-item key="4" v-if="node.isParent">取消子项</a-menu-item>
                                                                </a-menu>
                                                            </template>
                                                        </a-dropdown>
                                                    </template>
                                                </a-tree>
                                            </vue-scroll>
                                        </div>
                                    </div>
                                </a-dropdown>
                            </div>
                        </a-col>
                        <a-col flex="250px">
                            <div>
                                <a-dropdown :trigger="['click']" :disabled="!cubeId" :class="{'disabledStyle':!cubeId}">
                                <div class="ant-dropdown-link" @click.prevent="selectArea">
                                    <span v-if="!selectedAreaList.length">选择地区</span>
                                    <span v-else style="color:#595959">已选择地区（{{selectedAreaList.length}}）</span>
                                    <a-icon type="down" />
                                </div>
                                <div slot="overlay" class="regionList">
                                        <!-- <div class="dim-search" @click.stop>
                                            <a-input-search size="small" />
                                        </div> -->
                                        <div v-if="areaList.length>0" class="dim-scroll" @click.stop>
                                            <vue-scroll :ops="ops" style="height:100%">
                                                <a-tree
                                                        v-model="checkedKeysArea"
                                                        checkable
                                                        checkStrictly
                                                        :load-data="e=>{return onLoadData(e,'REGION',areaSheetId)}"
                                                        :tree-data="areaList"
                                                        :replaceFields="{title:'dimName',key:'dimCode',children:'children'}"
                                                    >
                                                <template #title="node">
                                                    <a-dropdown :trigger="['contextmenu']">
                                                        <span>{{ node.dimName }}</span>
                                                        <template #overlay>
                                                            <a-menu @click="({key:menuKey}) => onContextMenuClick(menuKey,node,'REGION',areaSheetId)">
                                                                <a-menu-item key="5">全选</a-menu-item>
                                                                <a-menu-item key="6">取消全选</a-menu-item>
                                                                <a-menu-item key="1">选择同级</a-menu-item>
                                                                <a-menu-item key="2">取消同级</a-menu-item>
                                                                <a-menu-item key="3" v-if="node.isParent">选择子项</a-menu-item>
                                                                <a-menu-item key="4" v-if="node.isParent">取消子项</a-menu-item>
                                                            </a-menu>
                                                        </template>
                                                    </a-dropdown>
                                                </template>
                                            </a-tree>
                                            </vue-scroll>
                                        </div>
                                        <div v-else>
                                            <a-empty style="line-height:300px" description="暂无数据" />
                                        </div>
                                    </div>
                                </a-dropdown>
                            </div>
                        </a-col>
                        <a-col flex="250px">
                            <div>
                                <a-dropdown :trigger="['click']" :disabled="!cubeId" :class="{'disabledStyle':!cubeId}">
                                <div class="ant-dropdown-link" @click.prevent="selectTime">
                                    <span v-if="!selectedTimeList.length">选择时间</span>
                                    <span v-else style="color:#595959">已选择时间（{{selectedTimeList.length}}）</span>
                                    <a-icon type="down" />
                                </div>
                                <div slot="overlay" class="regionList">
                                        <div class="dim-scroll" @click.stop>
                                            <vue-scroll :ops="ops" style="height:100%">
                                                <a-tree
                                                        v-model="checkedKeysTime"
                                                        checkable
                                                        checkStrictly
                                                        :load-data="e=>{return onLoadData(e,'TIME',timeSheetId)}"
                                                        :tree-data="timeList"
                                                        :replaceFields="{title:'dimName',key:'dimCode',children:'children'}"
                                                    >
                                                <template #title="node">
                                                    <a-dropdown :trigger="['contextmenu']">
                                                        <span>{{ node.dimName }}</span>
                                                        <template #overlay>
                                                            <a-menu @click="({key:menuKey}) => onContextMenuClick(menuKey,node,'TIME',timeSheetId)">
                                                                <a-menu-item key="5">全选</a-menu-item>
                                                                <a-menu-item key="6">取消全选</a-menu-item>
                                                                <a-menu-item key="1">选择同级</a-menu-item>
                                                                <a-menu-item key="2">取消同级</a-menu-item>
                                                                <a-menu-item key="3" v-if="node.isParent">选择子项</a-menu-item>
                                                                <a-menu-item key="4" v-if="node.isParent">取消子项</a-menu-item>
                                                            </a-menu>
                                                        </template>
                                                    </a-dropdown>
                                                </template>
                                        </a-tree>
                                            </vue-scroll>
                                        </div>
                                    </div>
                                </a-dropdown>
                            </div>
                        </a-col>
                    </a-row>
                    <a-row :gutter="[16,16]">
                        <a-col :span="24">
                            <a-row type="flex">
                                <a-col flex="100px" class="label">文件描述</a-col>
                                <a-col flex="auto">
                                    <quill-editor :options="editorOption" :content="fileDesc" @change="onEditorChange" style="height:150px" />
                                </a-col>
                            </a-row>
                        </a-col>
                    </a-row>

                    <div style="height:60px;"></div>   

                    <a-row :gutter="[16,16]">
                        <a-col :span="24">
                            <a-row type="flex">
                                <a-col flex="100px" class="label">文件上传</a-col>
                                <a-col flex="auto">
                                    <a-input style="width:300px" :disabled="true" :value="fileUrl" />
                                    <a-upload
                                        :show-upload-list="false" 
                                        @change="handlePreview" 
                                        :before-upload="handleChangeFile" 
                                        accept=".pdf"
                                    >
                                        <a-button type="primary"  class="upload-btn">选择文件</a-button>
                                    </a-upload>
                                </a-col>
                            </a-row>
                        </a-col>
                    </a-row>                 
                </div>

                <div class="btns">
                    <a-button @click="cancelEdit()">取消</a-button>
                    <a-button type="primary" @click="confirmEdit" :disabled="uploading">确定</a-button>
                </div>
            </a-modal>
        </a-layout-content>
    </a-layout>
</template>

<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { messageTips,getCookie } from '../../until/tools';
import config from '../../until/configs';
import reqwest from 'reqwest';
import { quillEditor } from 'vue-quill-editor';
const allColumns = [
{
    title: '序号',
    customRender: (text, record, index) => `${index + 1}`,
    key: '1',
    align:'center',
  },
  {
    title: '文件名',
    dataIndex: 'fileName',
    key: '2',
  },
  {
    title: '库名称',
    dataIndex: 'cubeName',
    key: '3',
  },
//   {
//     title: '维度名称',
//     dataIndex: 'dimName',
//     key: '4',
//   },
  {
    title: '文件描述',
    dataIndex: 'memo',
    key: '4',
    scopedSlots: {
      customRender: 'memoDesc',
    },
  },
  {
    title: '上传时间',
    dataIndex: 'createTime',
    key: '5',
  },
  {
    title: '更新时间',
    dataIndex: 'updateTime',
    key: '6',
  },
  {
    title: '上传者',
    dataIndex: 'realName',
    key: '7',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: '8',
    align:'center',
    scopedSlots: {
      customRender: 'operation',
    },
  },
];
export default {
    name:'Articles',
    components:{quillEditor},
    data() {
        return {
            allColumns,
            newName:'',
            selectedRowKeys:[],
            articles:[],
            keyword:'',
            page:{},
            rowSelection:{
                onChange: (selectedRowKeys) => {
                    this.selectedRowKeys = selectedRowKeys;
                },
                onSelect: (record, selected, selectedRows) => {
                    this.selectedRowKeys = selectedRows.map(item=>item.autoId);
                },
                onSelectAll: (selected, selectedRows) => {
                    this.selectedRowKeys = selectedRows.map(item=>item.autoId)
                },
            },
            uploadUrl:config.inputs_upload_dim_data+'?sid='+getCookie('bd_sid'),
            editorOption: {
                placeholder: "请在这里输入",
                height:200,
                theme:'snow',
                modules:{
                    toolbar:[
                        ['bold', 'italic'],    //加粗，斜体，下划线，删除线
                        ['blockquote'],     //引用，代码块
                        [{ 'header': 1 }, { 'header': 2 }],        // 标题，键值对的形式；1、2表示字体大小
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],     //列表
                        [{ 'indent': '-1'}, { 'indent': '+1' }],     // 缩进
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],     //几级标题
                        [{ 'align': [] }],    //对齐方式
                        //['image']    //上传图片、上传视频
                    ]
                }
            },
            fileName:'',
            fileDesc:'',
            waitingEdit:null,
            editVisible:false,
            fileUrl:null,
            waitingUploadFile:null,
            uploading:false,
            dimName:'',
            dimCode:'',
            cubeName:undefined,
            cubeId:'',
            areaList:[],//地区列表
            timeList:[],//时间列表
            IndicatorList:[],//指标列表
            selectedAreaList:[],//已选择地区
            selectedTimeList:[],//已选择时间
            selectedIndicatorList:[],//已选择指标
            checkedKeysArea:[],
            checkedKeysTime:[],
            checkedKeysIndicator:[],
            checkedKeys:[],
            ops: config.vueScrollSetting,
            indicatorSheetId:'',
            timeSheetId:'',
            areaSheetId:'',
            metaCubeList:[],//数据库树
        }
    },
    computed:{
        userInfo() {
            return this.$store.getters.getUserInfo;
        },
        folderId() {
            let id = this.$route.query.folderId;
            if(id) {
                return parseInt(id);
            }
            return null;
        },
        pageSetting(){
			let pageSize=0;
			if(this.page.allSize) {
				pageSize = this.page.allSize;
			}
			return {
				showQuickJumper:true,
				hideOnSinglePage:true,
				showTotal:()=>`共${pageSize}条`,
				total:pageSize,
			}
		},
    },
    watch:{
        folderId(value) {
            if(value) {
                this.getArticleList();
            }
        },
        checkedKeysArea(val) {
            if(val.checked){
                this.selectedAreaList=val.checked
            }else{
                this.selectedAreaList=val
            }
        },
        checkedKeysTime(val) {
            if(val.checked){
                this.selectedTimeList=val.checked
            }else{
                this.selectedTimeList=val
            }
        },
        checkedKeysIndicator(val) {
            if(val.checked){
                this.selectedIndicatorList=val.checked
            }else{
                this.selectedIndicatorList=val
            }
        },
    },
    mounted() {
        this.getArticleList()
        this.listMetaCubeTree()
    },
    methods:{
        onContextMenuClick(menuKey,node,codeName,sheetId){
            console.log(menuKey,node);
            if(menuKey==5){//全选
                var params = {}
                params = {dimType:codeName,sheetId:sheetId}
                if(this.jobId) {
                    params.jobId = this.jobId;
                }
                this.$http.getAllDimCodes(params).then(res=>{
                    if(res.dimensionCodeList) {
                        if(codeName=='TIME'){
                            this.checkedKeysTime = res.dimensionCodeList.map(item=>item.dimCode);
                        }else if(codeName=='REGION'){
                            this.checkedKeysArea=res.dimensionCodeList.map(item=>item.dimCode);
                        }else{
                            this.checkedKeysIndicator=res.dimensionCodeList.map(item=>item.dimCode);
                        }
                    } else {
                        messageTips(res.message,1);
                    }
                });
            }else if(menuKey==6){//取消全选
                if(codeName=='TIME'){
                    this.selectedTimeList=[]//真正处理完的数据
                    this.checkedKeysTime=[]//监视并为selectetimelist处理数据
                }else if(codeName=='REGION'){
                    this.selectedAreaList=[]
                    this.checkedKeysArea=[]
                }else{
                    this.selectedIndicatorList=[]
                    this.checkedKeysIndicator=[]
                }
            }else if(menuKey==1){//选择同级
                if(codeName=='TIME'){
                    this.timeList.filter((i)=>{
                        if(i.dimCode==node.parentId&&!node.isParent){
                            i.children.filter(i2=>{
                                if(!this.selectedTimeList.includes(i2.dimCode)){
                                    this.selectedTimeList.push(i2.dimCode);
                                }
                            })
                        }else{
                            if(node.isParent||node.parentId==-1){
                                if(!this.selectedTimeList.includes(i.dimCode)){
                                    this.selectedTimeList.push(i.dimCode)
                                }
                            }else{
                                if(!this.selectedTimeList.includes(i.dimCode)){
                                    this.selectedTimeList.push(i.dimCode)
                                }
                            }
                        }
                    })
                }else if(codeName=='REGION'){
                    this.areaList.filter((i)=>{
                        if(i.dimCode==node.parentId&&!node.isParent){
                            i.children.filter(i2=>{
                                if(!this.selectedAreaList.includes(i2.dimCode)){
                                    this.selectedAreaList.push(i2.dimCode);
                                }
                            })
                        }else{
                            if(node.isParent||node.parentId==-1){
                                if(!this.selectedAreaList.includes(i.dimCode)){
                                    this.selectedAreaList.push(i.dimCode)
                                }
                            }else{
                                if(!this.selectedAreaList.includes(i.dimCode)){
                                    this.selectedAreaList.push(i.dimCode)
                                }
                            }
                        }
                    })
                }else{
                    this.IndicatorList.filter((i)=>{
                        if(i.dimCode==node.parentId&&!node.isParent){
                            i.children.filter(i2=>{
                                if(!this.selectedIndicatorList.includes(i2.dimCode)){
                                    this.selectedIndicatorList.push(i2.dimCode);
                                }
                            })
                        }else{
                            if(node.isParent||node.parentId==-1){
                                if(!this.selectedIndicatorList.includes(i.dimCode)){
                                    this.selectedIndicatorList.push(i.dimCode)
                                }
                            }else{
                                if(!this.selectedIndicatorList.includes(i.dimCode)){
                                    this.selectedIndicatorList.push(i.dimCode)
                                }
                            }
                        }
                    })
                }
            }else if(menuKey==2){//取消同级
                if(codeName=='TIME'){
                    this.timeList.filter((i)=>{
                        if(i.dimCode==node.parentId&&!node.isParent){
                            i.children.filter(i2=>{
                                let index=this.selectedTimeList.indexOf(i2.dimCode)
                                if(index!==-1){
                                    this.selectedTimeList.splice(index,1)
                                }
                            })
                        }else{
                            if(node.isParent||node.parentId==-1){
                                let index=this.selectedTimeList.indexOf(i.dimCode)
                                if(index!==-1){
                                    this.selectedTimeList.splice(index,1)
                                }
                            }else{
                                let index=this.selectedTimeList.indexOf(i.dimCode)
                                if(index!==-1){
                                    this.selectedTimeList.splice(index,1)
                                }
                            }
                        }
                    })
                }else if(codeName=='REGION'){
                    this.areaList.filter((i)=>{
                        if(i.dimCode==node.parentId&&!node.isParent){
                            i.children.filter(i2=>{
                                let index=this.selectedAreaList.indexOf(i2.dimCode)
                                if(index!==-1){
                                    this.selectedAreaList.splice(index,1)
                                }
                            })
                        }else{
                            if(node.isParent||node.parentId==-1){
                                let index=this.selectedAreaList.indexOf(i.dimCode)
                                if(index!==-1){
                                    this.selectedAreaList.splice(index,1)
                                }
                            }else{
                                let index=this.selectedAreaList.indexOf(i.dimCode)
                                if(index!==-1){
                                    this.selectedAreaList.splice(index,1)
                                }
                            }
                        }
                    })
                }else{
                    this.IndicatorList.filter((i)=>{
                        if(i.dimCode==node.parentId&&!node.isParent){
                            i.children.filter(i2=>{
                                let index=this.selectedIndicatorList.indexOf(i2.dimCode)
                                if(index!==-1){
                                    this.selectedIndicatorList.splice(index,1)
                                }
                            })
                        }else{
                            if(node.isParent||node.parentId==-1){
                                let index=this.selectedIndicatorList.indexOf(i.dimCode)
                                if(index!==-1){
                                    this.selectedIndicatorList.splice(index,1)
                                }
                            }else{
                                let index=this.selectedIndicatorList.indexOf(i.dimCode)
                                if(index!==-1){
                                    this.selectedIndicatorList.splice(index,1)
                                }
                            }
                        }
                    })
                }
            }else if(menuKey==3){//选择子项
                let params = {
                    jobId:this.jobId,
                    parentCode:node.eventKey,
                    cubeId:this.cubeId,
                    dimType:codeName,
                    sheetId:sheetId
                }
                if(codeName=='TIME'){
                    this.$http.getDimensionChildren(params).then(res=>{
                        if(res.success){
                            res.dimensionList.filter((item)=>{
                                if(this.selectedTimeList.includes(item.dimCode)){
                                    return false
                                }else{
                                    this.selectedTimeList.push(item.dimCode)
                                }
                            })
                        }
                    })
                }else if(codeName=='REGION'){
                    this.$http.getDimensionChildren(params).then(res=>{
                        if(res.success){
                            res.dimensionList.filter((item)=>{
                                if(this.selectedAreaList.includes(item.dimCode)){
                                    return false
                                }else{
                                    this.selectedAreaList.push(item.dimCode)
                                }
                            })
                        }
                    })
                }else{
                    this.$http.getDimensionChildren(params).then(res=>{
                        if(res.success){
                            res.dimensionList.filter((item)=>{
                                if(this.selectedIndicatorList.includes(item.dimCode)){
                                    return false
                                }else{
                                    this.selectedIndicatorList.push(item.dimCode)
                                }
                            })
                        }
                    })
                }
            }else{//4取消子项
                if(node.isParent){
                    let params = {
                        jobId:this.jobId,
                        parentCode:node.eventKey,
                        cubeId:this.cubeId,
                        dimType:codeName,
                        sheetId:sheetId
                    }
                    if(codeName=='TIME'){
                        this.$http.getDimensionChildren(params).then(res=>{
                            if(res.success){
                                res.dimensionList.filter((item)=>{
                                    if(!this.selectedTimeList.includes(item.dimCode)){
                                        return false
                                    }else{
                                        this.selectedTimeList.splice(this.selectedTimeList.indexOf(item.dimCode),1)
                                    }
                                })
                            }
                        })
                    }else if(codeName=='REGION'){
                        this.$http.getDimensionChildren(params).then(res=>{
                            if(res.success){
                                res.dimensionList.filter((item)=>{
                                    if(!this.selectedAreaList.includes(item.dimCode)){
                                        return false
                                    }else{
                                        this.selectedAreaList.splice(this.selectedAreaList.indexOf(item.dimCode),1)
                                    }
                                })
                            }
                        })
                    }else{
                        this.$http.getDimensionChildren(params).then(res=>{
                            if(res.success){
                                res.dimensionList.filter((item)=>{
                                    if(!this.selectedIndicatorList.includes(item.dimCode)){
                                        return false
                                    }else{
                                        this.selectedIndicatorList.splice(this.selectedIndicatorList.indexOf(item.dimCode),1)
                                    }
                                })
                            }
                        })
                    }
                }
            }
        },
        listMetaCubeTree(){
            this.$http.listMetaCubeTree().then(res=>{
                this.metaCubeList=res.list
            })
        },
        popup(){
            this.cancelEdit()
            this.editVisible=true
        },
        onLoadData(treeNode,codeName,sheetId) {
            return new Promise(resolve => {
                // if (treeNode.dataRef.children) {
                //     resolve();
                //     return;
                // }
                let params = {
                    parentCode:treeNode.eventKey,
                    cubeId:this.cubeId,
                    dimType:codeName,
                    sheetId:sheetId
                }
                this.$http.getDimensionChildren(params).then(res=>{
                    res.dimensionList.forEach(item=>{
                        if(!item.isParent) {
                            item.isLeaf = true;//去掉箭头
                        }
                        // if(!item.chkDisabled) {
                        //     item.disabled = true;//禁掉响应
                        // }
                        item['title']=item.dimName
                        item['key']=item.dimCode
                    })
                    treeNode.dataRef.children = res.dimensionList
                    if(codeName=='REGION'){
                        this.areaList = [...this.areaList];
                    }else{
                        this.timeList=[...this.timeList]
                    }
                    resolve();                        
                    });
            });
        },
        //获取地区/时间维度信息
        getDimensions(val){
            let params = {
                cubeId: this.cubeId
            }
            if(val==1){
                //禁止选子项后再走接口
                if(this.areaList.length>0) {
                    return false;
                }
                this.$http.getDimensions(params).then(res=>{
                    if(res.success) {
                        const data=res.dataletBO.allDimensions.dimensionVOList[2].objList
                        this.areaSheetId=res.dataletBO.sheetInfo.sheetId
                        if(data!==undefined){
                            const temp = data.map((item)=>{
                            if(!item.isParent) {
                                item.isLeaf = true;//去掉箭头
                            }
                            // if(!item.chkDisabled) {
                            //     item.disabled = true;//禁掉响应
                            // }
                                return item
                            })
                            this.areaList=temp
                        }else{
                            this.areaList=[]
                        }
                    }
                })
            }else if(val==2){
                //禁止选子项后再走接口
                if(this.timeList.length>0) {
                    return false;
                }
                this.$http.getDimensions(params).then(res=>{
                    if(res.success) {
                        const data=res.dataletBO.allDimensions.dimensionVOList[1].objList
                        this.timeSheetId=res.dataletBO.sheetInfo.sheetId
                        if(data!==undefined){
                            const temp = data.map((item)=>{
                            if(!item.isParent) {
                                item.isLeaf = true;//去掉箭头
                            }
                            // if(!item.chkDisabled) {
                            //     item.disabled = true;//禁掉响应
                            // }
                                return item
                            })
                            this.timeList=temp
                        }else{
                            this.timeList=[]
                        }
                    }
                })
            }else{
                //禁止选子项后再走接口
                if(this.IndicatorList.length>0) {
                    return false;
                }
                this.$http.getDimensions(params).then(res=>{
                    if(res.success) {
                        const data=res.dataletBO.allDimensions.dimensionVOList[0].objList
                        this.indicatorSheetId=res.dataletBO.sheetInfo.sheetId
                        if(data!==undefined){
                            const temp = data.map((item)=>{
                            if(!item.isParent) {
                                item.isLeaf = true;//去掉箭头
                            }
                            // if(!item.chkDisabled) {
                            //     item.disabled = true;//禁掉响应
                            // }
                                return item
                            })
                            this.IndicatorList=temp
                        }else{
                            this.IndicatorList=[]
                        }
                    }
                })
            }
        },
        //选择地区
        selectArea(){
            this.getDimensions(1)
        },
        //选择指标
        selectIndicator(){
            this.getDimensions(3)
        },
        //选择时间
        selectTime(){
            this.getDimensions(2)
        },
        //选择库
        handleChangeCube(value,b,c){
            this.cubeId=c.triggerNode.eventKey
            this.checkedKeysArea=[]
            this.checkedKeysTime=[]
            this.checkedKeysIndicator=[]
        },
        //获取列表
        getArticleList(obj) {
            let params={}
            if(obj) {
                params={...obj}
            }
            return this.$http.inputs_get_article_newfolder(params).then(res=>{
                if(res.success){
                    this.articles = res.list;
                    this.page = res.page;
                }
                return true;
            })
        },

        setTree(arr,pid,result) {
            arr.forEach(item=>{
                if(item.parentId===pid) {
                    item.children = this.setTree(arr,item.folderId,[]);
                    result.push(item);
                }
            })
            return result;
        },

        onSearch(e) {
            let params = {currentPage:1}
            if(e) {
                params.fileName = e;
            }
            this.getArticleList(params);
        },
        changeTable(e) {
            let params = {currentPage:e.current}
            if(this.keyword) {
                params.fileName = this.keyword;
            }
            this.getArticleList(params);
        },
        cancelEdit() {
            this.editVisible = false;
            this.fileName = '';
            this.fileDesc = '';
            this.waitingEdit = null;
            this.fileUrl = null;
            this.waitingUploadFile = null;

            this.cubeId='';
            this.cubeName=undefined;//
            this.selectedIndicatorList=[];
            this.checkedKeysIndicator=[];
            this.selectedAreaList=[];
            this.checkedKeysArea=[];
            this.selectedTimeList=[];
            this.checkedKeysTime=[];
        },
        edit(item) {
            this.$http.inputs_get_article_newDetail({autoId:item.autoId,dimCode:item.dimCode}).then(res=>{
                console.log(res,'详情');
                if(res.success) {
                    if(res.dimStorage) {
                        this.editVisible = true;
                        this.waitingEdit = item.autoId;
                        this.fileName = item.fileName;
                        this.fileDesc = item.memo;
                        this.fileUrl = res.dimStorage.filePath;
                        this.dimName=item.dimName;
                        this.dimCode=item.dimCode;
                        this.cubeName=item.cubeName;
                        this.cubeId=item.cubeId;
                        //拿编辑的回显 刁钻数据
                        let r={}
                        let b=item.dimCode.match(/([A-Z]*):([0-9,]*)/g)
                        b.map(item=>{
                            let e=item.split(':')
                            r[e[0]]=e[1].split(',')
                        })
                        r.INDICATOR.pop()
                        r.REGION.pop()
                        this.checkedKeysIndicator=r.INDICATOR
                        this.checkedKeysArea=r.REGION
                        this.checkedKeysTime=r.TIME
                    }
                }
            })
        },
        confirmEdit() {
            const formData = new FormData();
            let funName;
            if(this.fileName==='') {
                messageTips('请填写文件名称',2);
                return false;
            }
            if(!this.waitingEdit) {
                if(!this.waitingUploadFile) {
                    messageTips('请上传文件',2);
                    return false;
                }
                // 新增
                funName = config.addDimFile+'?sid='+getCookie('bd_sid');
                // formData.append('dimName', this.dimName);
                formData.append('dimCode', 'INDICATOR:'+this.selectedIndicatorList.join()+',REGION:'+this.selectedAreaList.join()+',TIME:'+this.selectedTimeList.join());
                formData.append('cubeName', this.cubeName);
                formData.append('cubeId', this.cubeId);
                formData.append('file', this.waitingUploadFile);
                formData.append('fileName', this.fileName);//文件名
                formData.append('memo', this.fileDesc);//描述备注
            } else {
                // 修改
                funName = config.updateDimFile+'?sid='+getCookie('bd_sid');                
                formData.append('autoId', this.waitingEdit);//文件id
                formData.append('dimName', this.dimName);
                formData.append('dimCode', 'INDICATOR:'+this.selectedIndicatorList.join()+',REGION:'+this.selectedAreaList.join()+',TIME:'+this.selectedTimeList.join());
                formData.append('filePath', this.fileUrl);
                // formData.append('file', this.waitingUploadFile);
                formData.append('fileName', this.fileName);//文件名
                formData.append('memo', this.fileDesc);//描述备注
                formData.append('cubeName', this.cubeName);
                formData.append('cubeId', this.cubeId);
            }
            this.uploading = true;
            reqwest({
                url: funName,
                method: 'post',
                processData: false,
                data: formData,
                contentType : false,
                success: (res) => {
                    this.uploading = false;
                    if(res) {
                        if(res.success) {
                            if(this.waitingEdit) {
                                let params = {currentPage:this.page.currentPage}
                                if(this.keyword) {
                                    params.fileName = this.keyword;
                                }
                                this.getArticleList(params);
                            } else {
                                this.getArticleList();
                            }
                            messageTips(res.message,3);
                            this.editVisible = false;
                            this.fileName = '';
                            this.fileDesc = '';
                            this.fileUrl = null;
                            this.waitingUploadFile = null;
                            this.waitingEdit = null;
                            this.dimCode='',
                            this.dimName='',
                            this.cubeName='',
                            this.cubeId=''
                        } else {
                            messageTips(res.message,1);
                        }                        
                    }
                },
                error: () => {
                
                },
            });
        },
        onEditorChange(e) {
            this.fileDesc = e.html;
        },
        handlePreview({file}) {
            this.fileUrl = file.name;
        },
        handleChangeFile(info) {
            this.waitingUploadFile = info;
        },
        
        deleteArticle(autoId) {
            var that = this;
            this.$confirm({
                title: '提示',
                content: '您确定要删除吗？',
                okText: '确定',
                cancelText: '取消',
                centered:true,
                onOk() {
                    that.$http.inputs_delete_articles({autoIds:autoId}).then(res=>{
                            if(res.success) {
                                messageTips(res.message,3);
                                let params = {currentPage:that.page.currentPage}
                                if(that.keyword) {
                                    params.fileName = that.keyword;
                                }
                                that.getArticleList(params);
                            } else {
                                messageTips(res.message,1);
                            }
                    })
                }
            });
        },
        downloadFile(autoId){
            let url = config.originalDownload+'?autoId='+autoId+'&sid='+getCookie('bd_sid');
            window.open(url, '_blank');
        },
        multiDelete() {
            if(this.selectedRowKeys.length===0) {
                messageTips('请选择要删除的数据',2);
                return false;
            }
            this.deleteArticle(this.selectedRowKeys.join(','));
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '../../scss/articles.scss';
        /deep/.ant-tabs-tab-active{
        color: #FBA951;
        font-size: 16px;
        }
        /deep/.ant-tabs-tab:hover{
            color: #FBA951;
        }
        /deep/.ant-tabs-ink-bar{
            background-color: #FBA951;
        }
</style>